
<template>
  <div>
    <page-header class="mb-4">
      {{ $t('googleStoreManage') }}
      <template #subtitle>
        {{ $t('googleStoreReview') }}
      </template>
    </page-header>


    <v-card
      class="py-6 px-4 mt-4"
      color="#f1f1f1"
      flat
    >
      <div style="display: grid; grid-template-columns: 200px 1fr; grid-column-gap: 20px">
        <v-card>
          <v-navigation-drawer
            class="pt-6"
            permanent
          >
            <v-list
              dense
              nav
            >
              <v-list-item
                v-for="item in items"
                :key="item.title"
                link
              >
                <div class="mr-4">
                  <v-icon> {{ item.icon }}</v-icon>
                </div>

                <div>{{ item.title }}</div>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-card>
        <v-card height="400">
          <router-view/>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script>
  import PageHeader from '@/composable/InK/typography/PageHeader.vue'
  import ImgUploadDialog from '@/views/AadenPages/takeawayWeb/components/imgUploadDialog.vue'

  export default {
    name: 'Review.vue',
    components: { ImgUploadDialog, PageHeader },
    data () {
      return {
        items: [
          { title: 'Pursue', icon: 'mdi-storefront', to: '' },
          { title: 'Reviews', icon: 'mdi-message-draw', to: '' },
          { title: 'Accounts', icon: 'mdi-link', to: '' },
          { title: 'Ideas', icon: 'mdi-cog-outline', to: '' },
          { title: 'Support', icon: 'mdi-help-circle', to: '' },
        ],
      }
    },
  }
</script>

<style scoped>

</style>
